import { bindActionCreators } from 'redux';
import {
  fetchAppData,
  fetchTPASettings,
} from '@wix/communities-blog-client-common';

import { tagClicked } from '../../common/actions/tag-clicked';
import { getInstance } from '../../common/controller/helpers';
import { initializeActions as initializeCommonActions } from '../../common/controller/init-actions';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchTagCloudWidgetInitialData } from '../actions/fetch-initial-data';

export function initializeActions({ wixCodeApi, store, fedopsLogger }) {
  return {
    ...initializeCommonActions({ wixCodeApi, store, fedopsLogger }),
    ...bindActionCreators(
      {
        tagClicked,
      },
      store.dispatch,
    ),
  };
}

export {
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  appParams,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  await Promise.all([
    store.dispatch(
      fetchTPASettings({
        language: getQueryLocale(store.getState()) || language,
      }),
    ), // TODO: move to adapter
    store.dispatch(fetchAppData()), // TODO: move to adapter
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
  ]);
  await store.dispatch(fetchTagCloudWidgetInitialData());

  await store.dispatch(
    setBasicParams({
      viewMode,
      language,
      biPageNumber: platformAPIs.bi.pageNumber,
    }),
  );
  await store.dispatch(setAppConfig({ bundleName }));
  await store.dispatch(fetchTopology(instanceId));
}
