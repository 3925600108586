import { get } from 'lodash';
import {
  getCurrentUserRole,
  getCurrentUserSiteMemberId,
} from '@wix/communities-blog-client-common';
import { SEARCH_SUBMITTED } from '../../actions/search';
import { getInstanceValue } from '../../store/instance-values/instance-values-selectors';

const handler = (action, state) => {
  const { post, query } = action.payload;
  return {
    evid: 216,
    post_stable_id: post?.id,
    search_term: query,
    role: getCurrentUserRole(state),
    site_member_id: getCurrentUserSiteMemberId(state),
    visitor_id:
      getInstanceValue(state, 'aid') || getInstanceValue(state, 'uid'),
    is_demo: post.isDemo,
    eventMeta: {
      description: 'search submitted',
    },
  };
};

export const uouSearchSubmittedEvent = { [SEARCH_SUBMITTED]: handler };
