import { setViewMode } from '../store/basic-params/basic-params-actions';
import Wix from './wix-sdk-polyfill';

export default function listenToEditModeChange(store) {
  store.dispatch(setViewMode(Wix.Utils.getViewMode().toLowerCase()));

  Wix.addEventListener(Wix.Events.EDIT_MODE_CHANGE, ({ editMode }) => {
    store.dispatch(setViewMode(editMode.toLowerCase()));
  });
}
