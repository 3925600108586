import { keyBy, map, mapValues } from 'lodash';
import {
  resolveLegacyId,
  getMixedIdsMap,
  resolveId,
} from '@wix/communities-blog-client-common';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import { FETCH_POST_METADATA_SUCCESS } from '../../actions/fetch-post-metadata';
import { SET_POSTS } from '../../actions/set-posts';
import { UPDATE_POSTS_RATINGS } from './post-ratings-actions';

const postRatingsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_POSTS:
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS: {
      const nextState = mapValues(getMixedIdsMap(payload), (post) => ({
        id: post.id,
        averageRating: post.averageRating,
        totalRatings: post.totalRatings,
      }));

      return { ...state, ...nextState };
    }
    case FETCH_POST_METADATA_SUCCESS: {
      const legacyId = resolveLegacyId(payload);
      const id = resolveId(payload);

      if (!legacyId || !id) {
        return state;
      }
      return {
        ...state,
        [id]: {
          averageRating: payload.averageRating,
          totalRatings: payload.totalRatings,
        },
        [legacyId]: {
          id,
          averageRating: payload.averageRating,
          totalRatings: payload.totalRatings,
        },
      };
    }
    case UPDATE_POSTS_RATINGS:
      const nextState = resolveRatingsNextState(state, payload);

      return {
        ...state,
        ...nextState,
      };
    default:
      return state;
  }
};

const resolveRatingsNextState = (state, ratingsState) => {
  return map(ratingsState, (rs, legacyPostId) => {
    const id = state[legacyPostId]?.id;

    return rs.type === 'READY'
      ? {
          [id]: {
            averageRating: rs.average,
            totalRatings: rs.total,
          },
          [legacyPostId]: {
            id,
            averageRating: rs.average,
            totalRatings: rs.total,
          },
        }
      : {};
  }).reduce((acc, state) => {
    acc = { ...acc, ...state };
    return acc;
  }, {});
};

export default postRatingsReducer;
