import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_ENABLE_RATINGS_IN_VIEWER } from '@wix/communities-blog-experiments';
import { getIsPostRatingEnabled } from '../../../common/selectors/app-settings-selectors';
import { updatePostsRatings } from '../../../common/store/post-ratings/post-ratings-actions';

export async function initWixCommentsController({
  config,
  setProps,
  wixCodeApi,
  platformAPIs,
  type,
  compId,
  store,
  httpClient,
}) {
  const initializeCommentsController = (
    await import(
      /* webpackChunkName: "wix-comments-controller" */ './wix-comments-controller'
    )
  ).default;
  const withRatings = (() => {
    const state = store.getState();
    return (
      getIsPostRatingEnabled(state) &&
      isExperimentEnabled(state, EXPERIMENT_ENABLE_RATINGS_IN_VIEWER)
    );
  })();

  const wixCommentsApi = await initializeCommentsController({
    config,
    setProps,
    wixCodeApi,
    platformAPIs,
    type,
    compId,
    store,
    httpClient,
  });

  if (withRatings) {
    wixCommentsApi.watch.ratings.onChange((state) => {
      store.dispatch(updatePostsRatings(state));
    });
  }
  return wixCommentsApi;
}
