import { isOwner } from '../selectors/current-user-selectors';
import { getViewMode } from '../selectors/search-params-selectors';
import createAction from '../services/create-action';
export var CONTENT_MIGRATION_FETCH_STATUS_REQUEST = 'contentMigration/FETCH_STATUS_REQUEST';
export var CONTENT_MIGRATION_FETCH_STATUS_SUCCESS = 'contentMigration/FETCH_STATUS_SUCCESS';
export var CONTENT_MIGRATION_FETCH_STATUS_FAILURE = 'contentMigration/FETCH_STATUS_FAILURE';
export var CONTENT_MIGRATION_SET_STATUS = 'contentMigration/SET_STATUS';
export var contentMigrationFetchStatusRequest = createAction(CONTENT_MIGRATION_FETCH_STATUS_REQUEST);
export var contentMigrationFetchStatusSuccess = createAction(CONTENT_MIGRATION_FETCH_STATUS_SUCCESS);
export var contentMigrationFetchStatusFailure = createAction(CONTENT_MIGRATION_FETCH_STATUS_FAILURE);
export var contentMigrationSetStatus = createAction(CONTENT_MIGRATION_SET_STATUS);
export var MIGRATION_STATUS_PATH = '/_api/content-migration/details';
export var getMigrationStatusPath = function (viewMode) {
    return "".concat(MIGRATION_STATUS_PATH, "?viewMode=").concat(viewMode);
};
export var contentMigrationFetchStatus = function (viewMode) {
    return function (dispatch, getState, _a) {
        var request = _a.request;
        dispatch(contentMigrationFetchStatusRequest());
        var promise = request(getMigrationStatusPath(viewMode || getViewMode(getState())));
        return promise
            .then(function (details) { return dispatch(contentMigrationFetchStatusSuccess(details)); }, function () { return dispatch(contentMigrationFetchStatusFailure()); })
            .then(function () { return promise; });
    };
};
export var fetchContentMigrationStatusForBusinessManager = function () { return function (dispatch, getState) {
    var state = getState();
    return isOwner(state)
        ? dispatch(contentMigrationFetchStatus('editor'))
        : Promise.resolve();
}; };
