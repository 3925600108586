import { getViewMode } from '../selectors/search-params-selectors';
import createAction from '../services/create-action';
export var CONTENT_MIGRATION_ROLLBACK_REQUEST = 'contentMigration/ROLLBACK_REQUEST';
export var CONTENT_MIGRATION_ROLLBACK_SUCCESS = 'contentMigration/ROLLBACK_SUCCESS';
export var CONTENT_MIGRATION_ROLLBACK_FAILURE = 'contentMigration/ROLLBACK_FAILURE';
export var contentMigrationRollbackRequest = createAction(CONTENT_MIGRATION_ROLLBACK_REQUEST);
export var contentMigrationRollbackSuccess = createAction(CONTENT_MIGRATION_ROLLBACK_SUCCESS);
export var contentMigrationRollbackFailure = createAction(CONTENT_MIGRATION_ROLLBACK_FAILURE);
var MIGRATION_ROLLBACK_PATH = '/_api/content-migration/rollback';
export var getMigrationRollbackPath = function (viewMode) {
    return "".concat(MIGRATION_ROLLBACK_PATH, "?viewMode=").concat(viewMode);
};
export var contentMigrationRollback = function () {
    return function (dispatch, getState, _a) {
        var request = _a.request;
        dispatch(contentMigrationRollbackRequest());
        var viewMode = getViewMode(getState());
        var promise = request.post(getMigrationRollbackPath(viewMode));
        return promise
            .then(function (details) { return dispatch(contentMigrationRollbackSuccess(details)); }, function () { return dispatch(contentMigrationRollbackFailure()); })
            .then(function () { return promise; });
    };
};
