import { trimEnd } from 'lodash';
import { getContentText } from '@wix/communities-universal/dist/src/services/editor-content-text';
import { POST_SEO_DESCRIPTION_MAXLENGTH } from '@wix/communities-blog-universal/dist/src/constants/post';
import { getMultilingualQueryParam } from '../services/get-multilingual-query-param';
import { limitLength, removeTrailingSlash } from '../services/utils';
export var getPostSeoDescription = function (_a) {
    var seoDescription = _a.seoDescription, content = _a.content, excerpt = _a.excerpt;
    return limitLength(seoDescription ||
        excerpt ||
        getContentText(content, POST_SEO_DESCRIPTION_MAXLENGTH), POST_SEO_DESCRIPTION_MAXLENGTH);
};
export var getPostAmpUrl = function (postId, ampBaseUrl, postSlug) {
    if (!ampBaseUrl || !postId) {
        return;
    }
    return "".concat(removeTrailingSlash(ampBaseUrl), "/").concat(removeTrailingSlash(postSlug));
};
export var getCanonicalPostUrl = function (_a) {
    var post = _a.post, postPageSectionUrl = _a.postPageSectionUrl, customPostUrl = _a.customPostUrl, multilingual = _a.multilingual;
    if (customPostUrl) {
        var langParam = getMultilingualQueryParam(multilingual);
        return "".concat(customPostUrl).concat(langParam ? langParam : '');
    }
    if (post.url) {
        return "".concat(post.url.base).concat(post.url.path);
    }
    if (post.canonicalUrl) {
        return post.canonicalUrl;
    }
    return "".concat(trimEnd(postPageSectionUrl, '/'), "/").concat(post.slug);
};
