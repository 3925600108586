import { createPerformanceTracker } from '@wix/communities-blog-client-common';
import {
  createControllerId,
  createLogger,
  isFrameless,
} from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { createMonitoring } from '../../common/services/create-monitoring';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { MY_POSTS_PAGE_BUNDLE_NAME } from '../constants/my-posts-page';
import { createReduxStore } from './create-redux-store';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';

export const createMyPostsPageController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs },
  allCtrls,
  context,
  flowAPI,
) => {
  const { isEditor, isPreview, isSSR, isDebug, isProduction } =
    getEnvironment(wixCodeApi);
  const { fedopsLogger } = createMonitoring({ flowAPI });
  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);
  const bundleName = MY_POSTS_PAGE_BUNDLE_NAME;
  const log = createLogger(isDebug, isProduction);

  log('createMyPostsPageController', {
    appParams,
    allCtrls,
    wixCodeApi,
    isSSR,
    language,
  });

  let store;

  const pageReady = async () => {
    const controllerId = createControllerId();
    const perfTag = `${bundleName} ${controllerId}`;
    const perf = createPerformanceTracker(perfTag, { isDebug, isSSR });
    const pageReadyMarker = perf.trackStart(
      `${new Date().toISOString().slice(11)} pageReady`,
    );
    log('createMyPostsPageController.pageReady -> start');

    simulateControllerError(wixCodeApi, 'my-posts-page.pageReady');

    initLazyActions({ isSSR });

    let marker = perf.trackStart('createReduxStore', pageReadyMarker);
    store = createReduxStore({
      appParams,
      wixCodeApi,
      compId,
      fedopsLogger,
      isSSR,
      language,
      bundleName: perfTag,
      flowAPI,
    });
    perf.trackEnd(marker);

    marker = perf.trackStart('initializeActions', pageReadyMarker);
    const actions = initializeActions({
      wixCodeApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions({ store });
    perf.trackEnd(marker);

    await perf.trackPromise(
      'initializeStoreBaseData',
      () =>
        initializeStoreBaseData({
          wixCodeApi,
          store,
          language,
          platformAPIs,
          config,
          context,
          bundleName,
          appParams,
        }),
      pageReadyMarker,
    );
    listenToInstanceChange(wixCodeApi, appParams, store);

    log('createMyPostsPageController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);
    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
    });

    if (
      (isEditor || isPreview) &&
      !isFrameless({ state: store.getState(), wixCodeApi })
    ) {
      listenToEditModeChange(store);
      listenToSettingsChange(store);
    }

    refreshDataOnLogin({ wixCodeApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
    perf.trackEnd(pageReadyMarker);
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor
        ? handleProvisioning(
            appParams,
            fedopsLogger,
            wixCodeApi,
            setProps,
            pageReady,
            flowAPI,
          )
        : pageReady(),
    exports: () => {},
    updateConfig: (_$w, { style: { styleParams } }) => {
      if (!store) {
        return;
      }
      store.dispatch(setAppSettings({ style: styleParams }));
    },
  }).catch(console.error);
};
